import React from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const QuestionAndAnswer = ({ title, children }) => (
  <div className={"row"} style={{borderBottom: "1px solid #eee", marginBottom: "1em"}}>
    <div className={"col-6"}>
      <p>
        {title}
      </p>
    </div>

    <div className={"col-6"}>
      {children}
    </div>
  </div>
)

const ContactPage = ({ location }) => (
  <Layout>
    <SEO title="Frequently Asked Questions" currentUrl={location.pathname} />
    <div className={"container"}>
      <div className={"content"}>
        <div className={"title"}>
          <h1>Frequently Asked Questions</h1>
        </div>
        <QuestionAndAnswer title="Can I use McPiper with private GitLab instances?">
          <p>Yes, you can, simply replace gitlab.com with your domain in settings.</p>
        </QuestionAndAnswer>
        <QuestionAndAnswer title="How many projects can I monitor?">
          <p>The only limit is the GitLab API rate limiting, which is 2000 requests per minute.</p>
          <p>
            McPiper combines 10 project requests into one using GraphQL, so you can monitor up to 2000 projects per minute. 
            This might change in the next releases when more data will be fetched for a pipeline.
          </p>
        </QuestionAndAnswer>
        <QuestionAndAnswer title="What's the minimum GitLab version supported by McPiper?">
          <p>GitLab versions 12+ are supported by McPiper.</p>
        </QuestionAndAnswer>
        <QuestionAndAnswer title="Does McPiper work with VPNs?">
          <p>Yes, it works with VPNs. However, McPiper pings 1.1.1.1 (CloudFlare DNS) to determine network connectivity. If it can't connect to this host it stops pulling data as it assumes the internet is down.</p>
        </QuestionAndAnswer>
        <QuestionAndAnswer title="I can set up webhooks and use them to send message to Slack or Telegram, why do I need McPiper?">
          <p>Using Slack or Telegram also means you can get distracted easily by other messages.</p>
          <p>Plus you need to set up webhook for each repo manually. With McPiper you can select the repositories from a simple interface.</p>
        </QuestionAndAnswer>
        <QuestionAndAnswer title="Why do I need McPiper if I get email notifications from GitLab?">
          <p>Very similar to the question above, with having your email notifications on you can get other emails too which can distract you, especially if you try to practice <a href="https://blog.doist.com/deep-work/">deep work</a> sessions.</p>
        </QuestionAndAnswer>
        <QuestionAndAnswer title="Where can I generate GitLab API key?">
          <p>You can generate GitLab API key via your settings page in GitLab: <a href="https://gitlab.com/-/profile/personal_access_tokens">Personal Access Tokens</a>.</p>
          <p>Make sure you select `api` scope, as this is requied to make GraphQL API requests.</p>
        </QuestionAndAnswer>
        <QuestionAndAnswer title="Why does McPiper require API key with `api` (write) access?">
          <p>McPiper uses GitLab GraphQL to optimise the number of requests. GitLab GraphQL requires `api` access.</p>
          <p><a href="https://gitlab.com/gitlab-org/gitlab/-/issues/255177">This issue</a> is registered in GitLab issues tracker.</p>
        </QuestionAndAnswer>
        <QuestionAndAnswer title="What data does McPiper collect?">
          <p>McPiper uses AppCenter to collect crash reports and product analytics, this data is non-identifiable and helps to understand user behaviour and improve the product.</p>
        </QuestionAndAnswer>
        <QuestionAndAnswer title="How can I navigate to the pipeline page from McPiper?">
          <p>Clicking on the pipeline in the list of pipelines will open web URL in your default browser.</p>
          <p>Notifications also have View action which does the same thing.</p>
        </QuestionAndAnswer>
        <QuestionAndAnswer title="How do notifications work in McPiper?">
          <p>At the moment the notifications engine in McPiper is very simple.</p>
          <p>McPiper compares the status of the most recent pipeline in the project with the previous fetch.</p>
        </QuestionAndAnswer>
        <QuestionAndAnswer title="I still have an unanswered question, how can I get in touch?">
          <p>Feel free to <Link to="/contact/">contact</Link> me if you have any questions!</p>
        </QuestionAndAnswer>
      </div>
    </div>
  </Layout>
)

export default ContactPage
